import React from "react";
import "./style.css";
export default function LoadingComponent(props) {
    const colorClass = `loading-part loading-part-${props.color == "white" ? "white" : "classic"}`;
    return (React.createElement("div", { className: "loading-container", style: { paddingTop: props.extraPadding ? "15px" : "" } },
        React.createElement("div", { className: colorClass + " loading-part-first" }),
        React.createElement("div", { className: colorClass + " loading-part-second" }),
        React.createElement("div", { className: colorClass + " loading-part-third" }),
        props.message));
}
