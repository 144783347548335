import React from "react";
import InView from "react-intersection-observer";
import Button from "../../shared/ui/inputs/classicButton";
import "./style.css";
export function SecondLook() {
    const options = {
        triggerOnce: true,
        threshold: 0.8,
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "second_look-container" },
            React.createElement("h1", { className: "section-title" },
                "Narz\u0119dzia kt\u00F3rych potrzebujesz",
                React.createElement("span", { className: "text-primary-color" }, " w jednym miejscu")),
            React.createElement("div", { className: "articles-container" },
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-simplicity reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Galerie ze zdj\u0119ciami"),
                    "W kt\u00F3rych klienci obejrz\u0105 i zam\u00F3wi\u0105 zdj\u0119cia."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-customisibility reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Kategorie"),
                    "Dzielenie zdj\u0119\u0107 na wizualne foldery kt\u00F3re pozwalaj\u0105 na przypisanie w\u0142asnych cen."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-customisibility reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Kreator ofert"),
                    "Pozwala na dok\u0142adne dostosowanie cen i sprzedawanych produkt\u00F3w do aktualnych potrzeb."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-reliability reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Szablony"),
                    "Przyspieszaj\u0105 powtarzalne czynno\u015Bci takie jak dodawanie galerii."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-reliability reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Podzia\u0142 na grupy"),
                    "Ka\u017Cda galeria dzieli si\u0119 na grupy z w\u0142asnymi zdj\u0119ciami i ofertami."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-reliability reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Statystyki"),
                    "Dedykowany panel statystyk pozwala analizowa\u0107 zyski, popularno\u015B\u0107 produkt\u00F3w oraz liczb\u0119 wizyt w galerii."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-reliability reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Eksport galerii"),
                    "Zam\u00F3wione zdj\u0119cia mog\u0105 by\u0107 wyeksportowane bezpo\u015Brednio do laboratorium."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-reliability reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Powiadomienia mailowe"),
                    "Klienci mog\u0105 otrzymywa\u0107 emaile z informacj\u0105 o ich zam\u00F3wieniu. Oferujemy pe\u0142n\u0105 kontrol\u0119 nad tre\u015Bci\u0105 emaila."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-reliability reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "Analiza zdj\u0119\u0107 (beta)"),
                    "Podpowiada zdj\u0119cia klientom bazuj\u0105c na modelu AI analizuj\u0105cym twarze na fotografiach."))),
                React.createElement(InView, Object.assign({}, options), ({ inView, ref }) => (React.createElement("article", { ref: ref, className: `article-reliability reason ${inView ? "article-visible" : ""}` },
                    React.createElement("h3", null, "To nie wszystko!"),
                    "Mo\u017Cesz zapozna\u0107 si\u0119 dok\u0142adniej z naszym panelem i funkcjami jakie oferujemy tworz\u0105c konto."))))),
        React.createElement("div", { className: "third-part-container" },
            React.createElement("article", { className: "article-stats-example" },
                React.createElement("div", null,
                    React.createElement("h1", null,
                        "Zajrzyj do ",
                        React.createElement("span", { className: "text-primary-color" }, " statystyk")),
                    "Panel fotografa zawiera dok\u0142adne statystyki sprzeda\u017Cy.",
                    React.createElement("br", null),
                    "Zak\u0142adka ",
                    `"Na żywo"`,
                    " pokazuje wszystkie sesje",
                    React.createElement("br", null),
                    "klient\u00F3w w galerii w czasie rzeczywistym."),
                React.createElement("div", { className: "stats-example-container" },
                    React.createElement("img", { loading: "lazy", src: "/assets/images/stats-1.png", alt: "Statystyki przychod\u00F3w" }))),
            React.createElement("article", { className: "article-stats-example" },
                React.createElement("div", null,
                    React.createElement("h1", null,
                        "Eksportuj ",
                        React.createElement("span", { className: "text-primary-color" }, " zam\u00F3wienia")),
                    "Nasz rozbudowany system Eksportu grup",
                    React.createElement("br", null),
                    "pozwala na stworzenie schematu eksportowania",
                    React.createElement("br", null),
                    "i nazywanie plik\u00F3w w pe\u0142nej rozdzielczo\u015Bci",
                    React.createElement("br", null),
                    "zgodnie z ilo\u015Bci\u0105 zam\u00F3wionych sztuk.",
                    React.createElement("br", null),
                    "Mo\u017Cesz r\u00F3wnie\u017C eksportowa\u0107 zdj\u0119cia",
                    React.createElement("br", null),
                    "bezpo\u015Brednio do labu.")),
            React.createElement("article", { className: "article-stats-example" },
                React.createElement("div", { className: "last-message-wrapper" },
                    React.createElement("h1", null, "Zarejestruj si\u0119 za darmo"),
                    React.createElement("div", null, "Otrzymasz 20 z\u0142otych do wykorzystania w aplikacji."),
                    React.createElement("a", { href: "./createAccount.html", className: "no-style-link" },
                        React.createElement(Button, { value: "" }, "Utw\u00F3rz konto")))))));
}
