import React from "react";
export function Footer() {
    return (React.createElement("footer", null,
        React.createElement("div", { className: "footer-container" },
            React.createElement("div", { className: "footer-bar" },
                React.createElement("a", { href: "https://dokumentacja.fhub.pl" }, "Dokumentacja"),
                React.createElement("a", { href: "./contact.html" }, "Kontakt"),
                React.createElement("a", { href: "https://status.fhub.pl" }, "Status systemu")),
            React.createElement("div", { className: "footer-bar" },
                React.createElement("a", { href: "./statue.html" }, "Regulamin"),
                React.createElement("a", { href: "./privacyPolicy.html" }, "Polityka Prywatno\u015Bci"),
                React.createElement("a", { href: "./price-list.html" }, "Cennik"),
                React.createElement("div", null,
                    "\u00A9 ",
                    new Date().getFullYear(),
                    " Mateusz S\u0142omka")))));
}
