var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Config from "../config/env";
/**
 * @method executeRequest simplifies fetching data and adds host name for all request urls!
 */
export default function executeRequest(url, method, data, signal) {
    return __awaiter(this, void 0, void 0, function* () {
        const reqBody = method == "POST" || method == "PATCH" || method === "PUT" ?
            JSON.stringify(data)
            : undefined;
        const response = yield fetch(Config.devAdress + url, {
            method: method,
            credentials: "include",
            headers: {
                "Content-type": "application/json",
            },
            body: reqBody,
            cache: "no-cache",
            signal,
        });
        return yield response.json();
    });
}
