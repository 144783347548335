import React, { useEffect, useState } from "react";
import executeRequest from "../../../modules/request";
import Button from "../inputs/classicButton";
export default function AccountActions(props) {
    const [open, setOpen] = useState(false);
    const [loggedAccount, setLoggedAccount] = useState();
    useEffect(() => {
        executeRequest("/api/public/merchants/currencyPoints", "GET", {}).then((res) => {
            if (res.status !== 200)
                return setLoggedAccount(undefined);
            setLoggedAccount({
                email: res.merchantEmail,
            });
        });
    }, []);
    return (React.createElement("div", { className: "account_actions-container" },
        React.createElement(Button, { value: "", text: "Zaloguj si\u0119", version: "alternative", onClick: () => {
                if (loggedAccount) {
                    location.assign("/panel/panel.html");
                    return;
                }
                return setOpen(!open);
            } }),
        "Lub",
        React.createElement(Button, { value: "", text: "Utw\u00F3rz konto", onClick: () => window.location.assign("./createAccount.html") }),
        React.createElement("div", { onClick: () => setOpen(!open) }, open && props.children)));
}
