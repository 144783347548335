var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import executeRequest from "../../../modules/request";
import Button from "../inputs/classicButton";
import RedText from "./redInfo";
import { startAuthentication } from "@simplewebauthn/browser";
import { post } from "../../../modules/post";
import "./style.css";
import LoadingComponent from "../animations/loading";
const element = document.querySelector("#login-container");
export default function LoginComponent({ onClosed = () => { }, }) {
    const [isVerifying, setVerifying] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    useEffect(() => {
        var _a, _b;
        if (isVerifying == false)
            return;
        setLoginError("");
        setIsLoading(true);
        executeRequest(`/api/public/users/auth`, "POST", {
            email: (_a = emailInput.current) === null || _a === void 0 ? void 0 : _a.value,
            pass: (_b = passwordInput.current) === null || _b === void 0 ? void 0 : _b.value,
        }).then((res) => __awaiter(this, void 0, void 0, function* () {
            setVerifying(false);
            setIsLoading(false);
            if (res.status == 200) {
                const r = yield fetch("/api/public/merchants/2fa");
                const res = yield r.json();
                console.log(res);
                if (res.verified === true) {
                    return window.location.assign("./panel/panel.html");
                }
                let asseResp;
                try {
                    asseResp = yield startAuthentication(res);
                }
                catch (error) {
                    setLoginError("Drugi etap weryfikacji nieudany");
                    throw error;
                }
                const verified = yield post("/api/public/merchants/2fa", asseResp);
                if (verified === true) {
                    return window.location.assign("./panel/panel.html");
                }
                setLoginError("Drugi etap weryfikacji nieudany");
                return;
            }
            if (res.status === 403 || res.status == 429)
                return setLoginError("Spróbuj ponownie za godzinę...");
            setLoginError("Nieprawidłowe dane");
        }));
    }, [isVerifying]);
    return ReactDOM.createPortal(React.createElement("div", { className: "login-container", onClick: onClosed },
        React.createElement("div", { className: "login-box", onClick: (event) => event.stopPropagation() },
            React.createElement("form", { onSubmit: (event) => event.preventDefault() },
                React.createElement("div", { className: "login-to-gallery-wrapper" },
                    React.createElement("span", { className: "login-to-gallery-button-info" }, "Pr\u00F3bujesz zobaczy\u0107 zdj\u0119cia?"),
                    React.createElement("a", { href: "/client", className: "no-style-link" },
                        React.createElement(Button, { version: "alternative" }, "Zaloguj si\u0119 do galerii"))),
                React.createElement("h3", null, "Zaloguj si\u0119 jako sprzedawca"),
                React.createElement("label", null,
                    "Adres email:",
                    React.createElement("input", { type: "text", name: "email", placeholder: "Podaj adres email", autoComplete: "email", ref: emailInput, "data-form-type": "email" })),
                React.createElement("label", null,
                    "Has\u0142o:",
                    React.createElement("input", { type: "password", name: "pass", 
                        // eslint-disable-next-line react/no-unknown-property
                        datatype: "password", placeholder: "Podaj has\u0142o", autoComplete: "current-password", ref: passwordInput, "data-form-type": "password" })),
                React.createElement(Button, { value: "submit", version: "classic", onClick: () => {
                        if (isLoading)
                            return;
                        setVerifying(true);
                    }, isSubmit: true }, isLoading ?
                    React.createElement(LoadingComponent, { color: "white" })
                    : "Zaloguj się")),
            React.createElement(RedText, { text: loginError }))), element);
}
