import React from "react";
import { Main } from "./mainContainer";
import { Footer } from "../../shared/footer";
import { Navbar } from "../../shared/navbar";
export function Container() {
    return (React.createElement("div", null,
        React.createElement(Navbar, null),
        React.createElement(Main, null),
        React.createElement(Footer, null)));
}
