import React, { useEffect, useState } from "react";
import LoginComponent from "./ui/login/login";
import AccountActions from "./ui/nav/accountActions";
import { MainIcon } from "./ui/nav/mainIcon";
import NavigationContainer from "./ui/nav/navigation";
export function Navbar() {
    const [isBig, setIsBigger] = useState(true);
    useEffect(() => {
        document.onscroll = () => {
            const scroll = window.scrollY;
            if (scroll > 50) {
                setIsBigger(false);
            }
            else if (scroll < 50) {
                setIsBigger(true);
            }
        };
    }, []);
    return (React.createElement("nav", { className: "navbar-container landing-page-nav-container" +
            (isBig ? " navbar-container-bigger" : "") },
        React.createElement(MainIcon, { width: 50 }),
        React.createElement("div", { className: "right_nav_side-container" },
            React.createElement(NavigationContainer, null),
            React.createElement(AccountActions, null,
                React.createElement(LoginComponent, null)))));
}
